<!-- front/EventsList.client.vue-->
<template>
  <!-- Featured events. Set via props -->
  <div
    v-if="isHomeFeatured && featuredEvents.length > 0"
    class="featured-list-container"
  >
    <div class="section-title">
      <h2>{{ $t('featuredEvents') }}</h2>
    </div>
    <div class="event-list-container">
      <div class="grid-1-1-1-1">
        <div class="item" v-for="event in featuredEvents" :key="event.slug">
          <div class="item-holder">
            <div class="poster-holder">
              <NuxtLink
                v-if="!isEventExpired(event)"
                :to="`/event/${event.slug}?lang=${currentLocale}`"
              >
                <img
                  class="poster img-cover"
                  :src="(event.media as any)[0].files.thumb480"
                  :alt="event.title"
                  loading="lazy"
                />
              </NuxtLink>
              <img
                v-else
                class="poster img-cover"
                :src="(event.media as any)[0].files.thumb480"
                :alt="event.title"
                loading="lazy"
              />
            </div>

            <div class="text-holder padding-1">
              <NuxtLink
                v-if="!isEventExpired(event)"
                :to="`/event/${event.slug}`"
              >
                <h4>{{ event.title }}</h4>
              </NuxtLink>
              <h4 v-else>{{ event.title }}</h4>

              <div class="tags-holder">
                <NuxtLink
                  class="tag-button"
                  v-for="tag in event.tags"
                  :key="tag"
                >
                  <p class="small" @click="redirectToTag(tag)">
                    <Icon name="dashicons:star-empty" />
                    {{ toSentenceCase(tag) }}
                  </p>
                </NuxtLink>
              </div>
              <p v-if="(event.placeId as any)?.locationLink">
                <a
                  class="flex no-gap"
                  :href="(event.placeId as any)?.locationLink"
                  target="_blank"
                >
                  <Icon name="dashicons:location" />{{
                    (event.placeId as any)[0].title
                  }}
                </a>
              </p>
              <p class="flex no-gap" v-else>
                <Icon name="dashicons:location" />{{
                  (event.placeId as any)[0].title
                }}
              </p>
              <p class="flex no-gap">
                <Icon name="dashicons:clock" />{{
                  dateFormatter(event.date, currentLocale)
                }}
              </p>
              <NuxtLink
                v-if="!isEventExpired(event)"
                :to="`/event/${event.slug}?lang=${currentLocale}`"
                class="primary-button event-link-button"
                >{{ $t('ticketsAvailable') }}
              </NuxtLink>
              <p v-else class="color-red">{{ $t('eventExpired') }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Side featured events -->

      <!-- REMOVED -->

      <!--
      <div class="featured-rest-container" v-if="restOfTheEvents.length > 0">
        <div class="grid-1-1">
          <div
            class="item-small"
            v-for="event in restOfTheEvents"
            :key="event.slug"
          >
            <div class="item-holder-small">
              <NuxtLink
                v-if="!isEventExpired(event)"
                :to="`/event/${event.slug}?lang=${currentLocale}`"
              >
                <img
                  class="poster img-cover"
                  :src="(event.media as any)[0].files.thumb480"
                  :alt="event.title"
                  loading="lazy"
                />
              </NuxtLink>
              <img
                v-else
                class="poster img-cover"
                :src="(event.media as any)[0].files.thumb480"
                :alt="event.title"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      -->

      <!--
    <div v-else>
      <p>{{ $t('nothingFoundforThisSearchCriteria') }}</p>
    </div>
    --></div>
  </div>

  <!-- In your town events. Set via props -->
  <div v-if="isInYourTown && inYourTownEvents.length > 0">
    <div class="section-title">
      <h2>{{ $t('inYourTown') }}</h2>
    </div>
    <div class="grid-1-1-1-1">
      <div class="item" v-for="event in inYourTownEvents" :key="event.slug">
        <div class="item-holder">
          <div class="poster-holder">
            <NuxtLink
              v-if="!isEventExpired(event)"
              :to="`/event/${event.slug}?lang=${currentLocale}`"
            >
              <img
                class="poster img-cover"
                :src="(event.media as any).files.thumb480"
                :alt="event.title"
                loading="lazy"
              />
            </NuxtLink>
            <img
              v-else
              class="poster img-cover"
              :src="(event.media as any).files.thumb480"
              :alt="event.title"
              loading="lazy"
            />
          </div>
          <div class="text-holder padding-1">
            <div class="tags-holder">
              <NuxtLink class="tag-button" v-for="tag in event.tags" :key="tag">
                <p class="small" @click="redirectToTag(tag)">
                  <Icon name="dashicons:star-empty" />
                  {{ toSentenceCase(tag) }}
                </p>
              </NuxtLink>
            </div>
            <NuxtLink
              v-if="!isEventExpired(event)"
              :to="`/event/${event.slug}`"
            >
              <h4>{{ event.title }}</h4>
            </NuxtLink>
            <h4 v-else>{{ event.title }}</h4>

            <p v-if="(event.placeId as any)?.locationLink">
              <a
                class="flex no-gap"
                :href="(event.placeId as any)?.locationLink"
                target="_blank"
              >
                <Icon name="dashicons:location" />{{
                  (event.placeId as any).title
                }}
              </a>
            </p>
            <p class="flex no-gap" v-else>
              <Icon name="dashicons:location" />{{
                (event.placeId as any).title
              }}
            </p>
            <p class="flex no-gap">
              <Icon name="dashicons:clock" />{{
                dateFormatter(event.date, currentLocale)
              }}
            </p>
            <NuxtLink
              v-if="!isEventExpired(event)"
              :to="`/event/${event.slug}?lang=${currentLocale}`"
              class="primary-button event-link-button"
              >{{ $t('ticketsAvailable') }}
            </NuxtLink>
            <p v-else class="color-red">{{ $t('eventExpired') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Archive events. Set via props -->
  <div v-if="isArchive">
    <div class="section-title">
      <h2>{{ $t('allEvents') }}</h2>
    </div>
    <div class="grid-1-1-1-1">
      <div class="item" v-for="event in events" :key="event.slug">
        <div class="item-holder">
          <div class="poster-holder">
            <NuxtLink
              v-if="!isEventExpired(event)"
              :to="`/event/${event.slug}?lang=${currentLocale}`"
            >
              <img
                class="poster img-cover"
                :src="(event.media as any)[0].files.thumb480"
                :alt="event.title"
                loading="lazy"
              />
            </NuxtLink>
            <img
              v-else
              class="poster img-cover"
              :src="(event.media as any)[0].files.thumb480"
              :alt="event.title"
              loading="lazy"
            />
          </div>
          <div class="text-holder padding-1">
            <div class="tags-holder">
              <NuxtLink class="tag-button" v-for="tag in event.tags" :key="tag">
                <p class="small" @click="redirectToTag(tag)">
                  <Icon name="dashicons:star-empty" />
                  {{ toSentenceCase(tag) }}
                </p>
              </NuxtLink>
            </div>
            <NuxtLink
              v-if="!isEventExpired(event)"
              :to="`/event/${event.slug}`"
            >
              <h4>{{ event.title }}</h4>
            </NuxtLink>
            <h4 v-else>{{ event.title }}</h4>
            <p v-if="(event.placeId as any)[0].locationLink">
              <a
                class="flex no-gap"
                :href="(event.placeId as any)[0].locationLink"
                target="_blank"
              >
                <Icon name="dashicons:location" />{{
                  (event.placeId as any)[0].title
                }}
              </a>
            </p>
            <p class="flex no-gap" v-else>
              <Icon name="dashicons:location" />{{
                (event.placeId as any)[0].title
              }}
            </p>
            <p class="flex no-gap">
              <Icon name="dashicons:clock" />{{
                dateFormatter(event.date, currentLocale)
              }}
            </p>
            <NuxtLink
              v-if="!isEventExpired(event)"
              :to="`/event/${event.slug}?lang=${currentLocale}`"
              class="primary-button event-link-button"
              >{{ $t('ticketsAvailable') }}
            </NuxtLink>
            <p v-else class="color-red">{{ $t('eventExpired') }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Infinite loading with observer
    <div ref="loadMoreTriggerDiv" class="load-more-trigger"> 
    </div>
    -->

    <div
      v-if="currentPage <= totalPages && totalEvents > limit"
      class="text-align-center mt-2 mb-2"
    >
      <div class="secondary-button" @click="loadMoreClick">
        <span class="load-more-button">{{ $t('LoadMoreButton') }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { dateFormatter } from '@/composables/dateFormatter'
import type { IEvent, IPlace } from '@/types'

const props = defineProps({
  isHomeFeatured: {
    type: Boolean,
    default: false,
  },
  isInYourTown: {
    type: Boolean,
    default: false,
  },
  isArchive: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['tag-selected'])

const { locale } = useI18n()
const currentLocale = ref(locale.value)
const router = useRouter()
const totalPages = ref(1)
const totalEvents = ref(0)
const limit = ref(8)
const currentPage = ref(1)

// Watch for changes in the locale to update the currentLocale
watch(locale, (newLocale) => {
  currentLocale.value = newLocale
})

// Validate props not to confuse component
if (props.isHomeFeatured && props.isInYourTown) {
  throw new Error(
    'isHomeFeatured and isInYourTown cannot be true at the same time'
  )
}

const eventStore = useEventStoreFront()

const params = ref({
  isHomeFeatured: props.isHomeFeatured,
  isInYourTown: props.isInYourTown,
  isArchive: props.isArchive,
  page: currentPage.value,
  limit: limit.value,
  clientCity: '',
  genre: '',
})
const showEvents = ref(true)
const events = ref<(IEvent & { placeId?: IPlace })[]>([])
const featuredEvents = ref()
const restOfTheEvents = ref()
const inYourTownEvents = ref()
// const prevScrollY = ref(0)

const loadMoreTriggerDiv = ref<HTMLDivElement | null>(null) // Type for observed div
const isInView = ref(false) // Reactive flag for viewport status

let observer: IntersectionObserver | undefined // Observer variable with type

// Helper
const toSentenceCase = (str: string): string => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toUpperCase()
}

// Set if the event is expired
const getEventLink = (event: any) => {
  if (isEventExpired(event)) {
    return '#'
  }
  return `/event/${event.slug}?lang=${currentLocale.value}`
}

const isEventExpired = (event: IEvent & { placeId?: IPlace }) => {
  return new Date(event?.date) < new Date()
}

// Emit and set query params
const redirectToTag = (tag: any) => {
  emit('tag-selected', tag)
  router.push({
    path: '/event/tag',
    query: {
      'selected-tag': tag,
      'selected-tag-label': tag,
      lang: locale.value,
    },
  })
}

// If is home featured
if (props.isHomeFeatured) {
  const eventsData = await eventStore.getList(params.value)
  // console.log('eventsData', eventsData);

  featuredEvents.value = (eventsData as any).featuredEvents ?? []
  restOfTheEvents.value = (eventsData as any).restOfTheEvents ?? []
} else {
  featuredEvents.value = []
  restOfTheEvents.value = []
}

// If is in your town
if (props.isInYourTown) {
  interface IpData {
    city: string
  }

  const ipData = await $fetch<IpData>('https://ipapi.co/json/')

  params.value.clientCity = ipData?.city == 'Nis' ? 'Niš' : ipData?.city
  // TODO / Čačak, Kruševac, Zaječar, Bečej, Vršac, Šid, Bačka Palanka, Ćuprija, Šid, Vršac, Paraćin, Pećinci, Bačka toPlainHandler, Niška Banja, Kuršumlija
  // Vrnjačka Banja, Odžaci, Bajna Bašta, Požarevac, Nova Varoš

  // console.log('ipData', ipData);

  const eventsData = await eventStore.getList(params.value)
  inYourTownEvents.value = (eventsData as any).inYourTownEvents ?? []
} else {
  inYourTownEvents.value = []
}

// Get all events, artchive page is page for all events
const getArchiveEvents = async () => {
  if (params.value.page > totalPages.value) return
  const eventsData = await eventStore.getList(params.value)
  events.value.push(...((eventsData as any)?.archiveEvents ?? []))
  totalPages.value = (eventsData as any).totalPages
  totalEvents.value = (eventsData as any).totalEvents
  params.value.page++
  currentPage.value++
}

// If is archive
if (props.isArchive) {
  await getArchiveEvents()
}

/*
const handleLoadMore = async () => {
  const currentScrollY = window.scrollY
  const bottomOfWindow =
    window.innerHeight + currentScrollY >= document.documentElement.scrollHeight

  // Check if the user is scrolling down and reached the bottom
  if (currentScrollY > prevScrollY.value && bottomOfWindow) {
    // console.log('Reached the bottom while scrolling down!')
    await getArchiveEvents()
  }

  // Update the previous scroll position
  prevScrollY.value = currentScrollY
}
  */

const loadMoreClick = async () => {
  await getArchiveEvents()
}

const handleIntersection = async (entries: IntersectionObserverEntry[]) => {
  if (entries[0].isIntersecting) {
    isInView.value = true
    await getArchiveEvents()
  } else {
    isInView.value = false
  }
}

onMounted(() => {
  // Initialize the IntersectionObserver
  observer = new IntersectionObserver(handleIntersection, {
    threshold: 0.1, // Adjust threshold as needed
  })
  if (loadMoreTriggerDiv.value) {
    observer.observe(loadMoreTriggerDiv.value)
  }
  showEvents.value = true
})

onUnmounted(() => {
  // window.removeEventListener('scroll', handleLoadMore)
  if (observer && loadMoreTriggerDiv.value)
    observer.unobserve(loadMoreTriggerDiv.value)
})
</script>

<style scoped>
.event-list-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0 2em;
  align-items: center;
  justify-items: start;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  min-height: 400px;
  position: relative;
}

.item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.event-link-button {
  position: absolute;
  bottom: 1em;
  left: 1em;
  right: 1em;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

/* Set event item title height to predict 2 or 3 line titles */
.item h2,
.item h3,
.item h4 {
  min-height: 2em;
}

.item-holder {
  overflow: hidden;
}

.item-holder .text-holder {
  height: 240px;
}

.item-small {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  position: relative;
}

.item-small:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.item-holder-small {
  overflow: hidden;
}

.item-in-town {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  position: relative;
}

.item-in-town:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.poster {
  width: 100%;
  height: 200px;
  transition: filter 0.3s ease;
  background-color: #f5f5f5;
}

.featured-list-container .poster {
  height: 155px;
}

.item-in-town-holder .poster {
  height: 200px;
}

.item-holder:hover .poster {
  filter: grayscale(100%);
}

.padding-1 {
  padding: 1rem;
}

h2 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
  color: #333;
}

p {
  font-size: 1rem;
  color: #666;
  margin: 0.5rem 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.primary-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #06d37e;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.primary-button:hover {
  background-color: #04b56a;
}

.color-red {
  color: #c6363b;
}

.tags-holder {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag-button {
  display: inline-block;
  padding: 0.1em 0.5em;
  background-color: #f7b731;
  color: #252525;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.tag-button p {
  margin: 0;
  text-transform: capitalize;
  color: #fff;
}

.tag-button:hover {
  background-color: #f4a007;
  color: #000;
  cursor: pointer;
}

.tag-button:hover svg,
.tag-button:hover p {
  fill: #000;
  color: #000;
}

.load-more-trigger {
  height: 180px;
  margin-top: 500px; /* to make it initially out of view */
}

.load-more-button {
  padding: 2em;
  font-size: 1.5em;
  text-transform: uppercase;
}

@media (min-width: 2000px) {
  .poster {
    height: 230px;
  }
  .item {
    min-height: 420px;
  }
}

@media (max-width: 1369px) {
  .poster {
    height: 160px;
  }
}

@media (max-width: 1025px) {
  .grid-1-1-1,
  .event-list-container,
  .featured-rest-container {
    grid-template-columns: 1fr;
    grid-gap: 2em;
    width: 100%;
  }

  .poster {
    width: 100%;
    height: auto;
  }
}
</style>
